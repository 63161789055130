import type { SearchFacetMenuProps } from '@components/search-facet-menu'

export const contentTypeConversionMap: Map<string, string> = new Map([
  ['Classes', 'Class'],
  ['Medical services', 'Medical service'],
  ['Physicians', 'Physician'],
  ['Locations', 'Location'],
  ['News topics', 'News topic'],
  ['Authors', 'Author'],
  ['Related pages', 'Related page'],
])

export const doctorFacetConfigMap: SearchFacetMenuProps['facetConfigMap'] = new Map([
  ['specialties_facet', { title: 'Specialty', operator: 'or' }],
  ['medical_groups_facet', { title: 'Medical group', operator: 'or' }],
  ['insurances_facet', { title: 'Insurance', operator: 'or' }],
  ['appointment_options', { title: 'Appointment options', operator: 'and' }],
  ['gender', { title: 'Gender of provider', operator: 'or' }],
  ['languages_facet', { title: 'Language spoken', operator: 'or' }],
  ['interests_facet', { title: 'Area of focus', operator: 'or' }],
  ['provider_type', { title: 'Provider type', operator: 'or' }],
  ['hospital_affiliations_facet', { title: 'Hospital affiliation', operator: 'or' }],
])

export const locationFacetConfigMap: SearchFacetMenuProps['facetConfigMap'] = new Map([
  ['place_type_facet', { title: 'Location type', operator: 'or' }],
  ['medical_groups_facet', { title: 'Medical group', operator: 'or' }],
  ['services_facet', { title: 'Services', operator: 'or' }],
  ['features_facet', { title: 'Features', operator: 'or' }],
])

export const relatedPagesFacetConfigMap: SearchFacetMenuProps['facetConfigMap'] = new Map([
  ['contentType', { title: 'Page type', operator: 'or' }],
])

export enum WaitTimesStatusEnum {
  Normal = 'normal',
  HighVolume = 'high-volume',
  SoftClosure = 'soft-closure',
  Unavailable = 'unavailable',
}
